<template>
  <div class="footer">
    <nav class="nav nav-pills flex-sm-row">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="footer-menu col-md-6">
            <div class="footer-list">
              <a class="nav-item flex-md-fill text-sm-left nav-link text-decoration-none" href="#scrollspyHeading1">CONSEPT</a>
            </div>
            <div class="footer-list">
              <a class="nav-item flex-md-fill text-sm-left nav-link text-decoration-none" href="#scrollspyHeading2">WORKS</a>
            </div>
            <div class="footer-list">
              <a class="nav-item flex-md-fill text-sm-left nav-link text-decoration-none" href="#scrollspyHeading3">IMAGE</a>
            </div>
            <div class="footer-list">
              <a class="nav-item flex-md-fill text-sm-left nav-link text-decoration-none" href="#scrollspyHeading4" tabindex="-1" aria-disabled="true">CUSTOMS</a>
            </div>
            <div class="footer-list">
              <a class="nav-item flex-md-fill text-sm-left nav-link text-decoration-none" href="#scrollspyHeading5" tabindex="-1" aria-disabled="true">PRODUCTS</a>
            </div>
            <div class="footer-list"></div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-3 d-grid justify-content-center">
            <a class="" href="#scrollspyHeading7"><img src="../assets/logo.png" alt="logo" class="img-logo img-fluid" /></a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<style scoped>
body {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #070707;
}

.products {
  padding-bottom: 5vh;
}
.nav {
  padding: 70px;
}
.nav-item {
  color: #070707;
  text-align: left;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.2em;
}
.footer {
  background-color: #fff615;
}
.footer-menu {
  display: flex;
  width: 50%;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .footer-menu {
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
  }
  .img-logo {
    padding-top: 30px;
    padding-bottom: 8px;
  }
  .col-md-1 {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
  .nav {
    padding: 37px;
  }
  .footer-list {
    min-width: 130px;
  }
  .img-logo {
    width: 103px;
    padding-top: 30px;
    padding-bottom: 8px;
  }
  .footer-menu {
    flex-wrap: wrap;
    justify-content: normal;
  }
}
</style>
