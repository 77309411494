<template>
  <div class="image">
    <div class="text-start position-relative">
      <img src="../assets/bg3.png" alt="imageBg" class="image-bg position-absolute img-fluid" />
      <img src="../assets/image.title.png" alt="imageTitle" class="image-title position-relative" />
    </div>
    <div class="position-relative">
      <div class="container">
        <div class="content-title pb-4">
          <h4>LIHとは。<br />地域に根差した<br class="d-md-none d-sm-block" /><span class="yellow">サスティブナルな創造。</span></h4>
        </div>
        <div class="content-descliption">
          <h6>
            トンガルマン LIHは、地域に根差したマネタイズを創造して<br />
            持続可能なコンテンツをご提供するユニットです。<br />
            地域のみなさんが参加したくなる世界観をご提供します。
          </h6>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-6 cover-area">
            <div class="container col-md-6 cover-box">
              <div class="bg-skew-border" v-show="active || hold">
                <div class="cover-text text-center d-flex">
                  <div class="border-title position-absolute"></div>
                  <img src="../assets/arrow.png" alt="arrow" class="arrow" />
                  <p class="cover-title" v-show="text[0]">メタバース</p>
                  <p class="cover-title" v-show="text[1]">リープモーション</p>
                  <p class="cover-title" v-show="text[2]">DAO</p>
                  <p class="cover-title" v-show="text[3]">5G</p>
                  <p class="cover-title" v-show="text[4]">人工知能(AI)</p>
                  <p class="cover-title" v-show="text[5]">複合現実 (MR)</p>
                  <p class="cover-title" v-show="text[6]">デジタルサイネージ</p>
                  <p class="cover-title" v-show="text[7]">バーチャルリアリティ(VR)</p>
                  <p class="cover-title" v-show="text[8]">Web</p>
                  <p class="cover-title" v-show="text[9]">拡張現実（AR）</p>
                  <p class="cover-title" v-show="text[10]">NFT</p>
                  <p class="cover-title" v-show="text[11]">App</p>
                  <p class="cover-title" v-show="text[12]">映像制作</p>
                </div>
                <div class="position-relative">
                  <div class="border-description position-absolute"></div>
                  <p class="cover-description" v-show="text[0]">アバターを用いた仮想空間での授業実施や、修学旅行等、バーチャル空間を活用した体験ができる。</p>
                  <p class="cover-description" v-show="text[1]">マウスや画面タッチを用いずに手のジェスチャーによって<br />コンピューターを操作し直観的に操作することができる。</p>
                  <p class="cover-description" v-show="text[2]">特産物を軸とした地域コミュニティの形成や、コアファンの醸成ができる。</p>
                  <p class="cover-description" v-show="text[3]">観光地を舞台にしたアクションゲームや特産品に特化したゲーム等、地域PRに活用できる。</p>
                  <p class="cover-description" v-show="text[4]">観光案内での多言語AIの導入や、市民からの問い合わせに自動応答するチャットボットの構築ができる。</p>
                  <p class="cover-description" v-show="text[5]">3Dホログラムを活用した授業、<br />リアルタイム翻訳等、ICT教育への貢献が期待できる。</p>
                  <p class="cover-description" v-show="text[6]">一般的な広告だけでなく、<br />非接触型の観光紹介ツールとして活用できる。</p>
                  <p class="cover-description" v-show="text[7]">3Dホログラムを活用した授業、<br />リアルタイム翻訳等、ICT教育への貢献が期待できる。</p>
                  <p class="cover-description" v-show="text[8]">Webを活用した町おこしや地方の魅力を発信するWebメディアの構築ができる。</p>
                  <p class="cover-description" v-show="text[9]">観光コンテンツに利用することでより臨場感が高く、多くの情報 伝達が可能となる。</p>
                  <p class="cover-description" v-show="text[10]">伝統工芸とNFTのコラボや<br />ふるさと納税の返礼品等で<br />活用できる。</p>
                  <p class="cover-description" v-show="text[11]">通信環境を改善することで、<br />テレワーク環境や通信を利用した学習等実現しやすくなる。</p>
                  <p class="cover-description" v-show="text[12]">地方や産業のPR動画作成による<br />伝統産業の活性化が期待できる。</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 image-area">
            <div class="">
              <ul class="honeycomb">
                <li class="honeycomb-cell image-position01" @mouseover="show(0)" @mouseout="hide(0)" @click="tap(0)" v-bind:class="{ hold: text[0] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position02" @mouseover="show(1)" @mouseout="hide(1)" @click="tap(1)" v-bind:class="{ hold: text[1] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li>
                  <img class="image-icon" src="../assets/icon-image.png" />
                </li>
              </ul>
              <ul class="honeycomb">
                <li class="honeycomb-cell image-position03" @mouseover="show(2)" @mouseout="hide(2)" @click="tap(2)" v-bind:class="{ hold: text[2] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position04" @mouseover="show(3)" @mouseout="hide(3)" @click="tap(3)" v-bind:class="{ hold: text[3] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
              </ul>
              <ul class="honeycomb">
                <li class="honeycomb-cell image-position05" @mouseover="show(4)" @mouseout="hide(4)" @click="tap(4)" v-bind:class="{ hold: text[4] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position06" @mouseover="show(5)" @mouseout="hide(5)" @click="tap(5)" v-bind:class="{ hold: text[5] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
              </ul>
              <ul class="honeycomb">
                <li class="honeycomb-cell image-position07" @mouseover="show(6)" @mouseout="hide(6)" @click="tap(6)" v-bind:class="{ hold: text[6] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position08" @mouseover="show(7)" @mouseout="hide(7)" @click="tap(7)" v-bind:class="{ hold: text[7] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position09" @mouseover="show(8)" @mouseout="hide(8)" @click="tap(8)" v-bind:class="{ hold: text[8] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position10" @mouseover="show(9)" @mouseout="hide(9)" @click="tap(9)" v-bind:class="{ hold: text[9] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position11" @mouseover="show(10)" @mouseout="hide(10)" @click="tap(10)" v-bind:class="{ hold: text[10] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
              </ul>
              <ul class="honeycomb">
                <li class="honeycomb-cell image-position12" @mouseover="show(11)" @mouseout="hide(11)" @click="tap(11)" v-bind:class="{ hold: text[11] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
                <li class="honeycomb-cell image-position13" @mouseover="show(12)" @mouseout="hide(12)" @click="tap(12)" v-bind:class="{ hold: text[12] }">
                  <div class="honeycomb-cell-image"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: [false, false, false],
      active: false,
      hold: false,
    };
  },
  methods: {
    show: function (target) {
      if (!this.hold) {
        this.active = true;
        this.text[target] = true;
      }
    },
    hide: function (target) {
      if (!this.hold) {
        this.active = false;
        this.text[target] = false;
      }
    },
    tap(target) {
      if (target == this.hold) {
        this.hold = false;
      } else {
        this.text[this.hold] = false;
        this.hold = target;
        this.text[target] = true;
      }
    },
  },
};
</script>

<style scoped>
.image {
  margin-bottom: 135px;
}
.image-bg {
  top: -16vw;
  left: 19%;
}
.image-area {
  min-height: 600px;
}
.image-icon {
  position: absolute;
  top: -24px;
  right: 100px;
}
.image-title {
  max-width: 65%;
  padding-bottom: 30px;
  height: auto;
}
.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: right;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  transform: translateY(34.375px);
  position: absolute;
  right: 0;
}
.honeycomb li {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
}
.honeycomb-cell {
  flex: 0 1 5vw;
  max-width: 250px;
  height: 3vw;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 2;
  transform: rotate(0.045turn);
}

.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}

.honeycomb-cell-image {
  object-fit: cover;
  object-position: center;
}
.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell-image {
  display: block;
  position: absolute;
  z-index: -1;
}

.honeycomb-cell::before,
.honeycomb-cell-image {
  left: 0;
  background-color: #040404;
  width: 100px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  height: 110px;
}

.honeycomb-cell::before {
  background: #f5f5f5;
  transform: scale(1.025);
}
.honeycomb-cell::after {
  transition: opacity 350ms;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
  width: 65px;
  height: 74px;
  transform: rotate(-0.045turn);
}
.honeycomb-cell:hover .honeycomb-cell__title {
  opacity: 0;
}
.honeycomb-cell:hover::before {
  background: #fff615;
}
.hold::before {
  background: #fff615;
}
.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

.icon2 {
  position: absolute;
  right: 0;
}

.border-title {
  width: 30px;
  border: 2px solid #505050;
}
.border-description {
  width: 72px;
  right: 0px;
  border: 2px solid #505050;
}

@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}
@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}
@media (min-width: 1100px) {
  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}
.cover-box {
  margin-right: 110px;
  margin-top: 168px;
}

.bg-skew-border {
  border: 1px solid #454545;
}

.image-text {
  color: aliceblue;
}
.content-title {
  font-weight: normal;
  font-size: 42px;
  border-color: #414241;
  text-align: left;
}
.cover-text {
  background-color: #2c2c2c;
  background-image: repeating-linear-gradient(-68deg, #040404, #040404 7px, transparent 9px, transparent 8px);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.2em;
  color: #fde71c;
  overflow: hidden;
}
.cover-title {
  flex-direction: column;
  display: inline-flex;
  justify-content: center;
  margin: 0;
}
.cover-description {
  border-top: 1px solid #454545;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #ebebeb;
  padding: 34px 42px 16px;
}
.arrow {
  width: 18px;
  height: 26px;
  margin: 24px 13px 24px 32px;
}

.yellow {
  color: #fff615;
}
h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 61px;
}
.content-descliption h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #ebebeb;
}

.image-position01 {
  right: 429px;
  top: 1px;
}
.image-position02 {
  right: 422px;
  top: 33px;
}
.image-position03 {
  right: 607px;
  top: -25px;
}
.image-position04 {
  right: 601px;
  top: 6px;
}

.image-position05 {
  right: 580px;
  top: -20px;
}
.image-position06 {
  right: 572px;
  top: 11px;
}
.image-position07 {
  right: 360px;
  top: -45px;
}
.image-position08 {
  right: 351px;
  top: -14px;
}
.image-position09 {
  right: 344px;
  top: 17px;
}
.image-position10 {
  right: 336px;
  top: 50px;
}
.image-position11 {
  right: 327px;
  top: 83px;
}

.image-position12 {
  right: 524px;
  top: -9px;
}
.image-position13 {
  right: 517px;
  top: 23px;
}
.honeycomb-cell.image-position01::after {
  background-image: url("../assets/metaverse.png");
  top: 29px;
  left: 18px;
}

.honeycomb-cell.image-position02::after {
  background-image: url("../assets/leapMotion.png");
  top: 27px;
  left: 11px;
}
.honeycomb-cell.image-position03::after {
  background-image: url("../assets/DAO.png");
  width: 71px;
  height: 82px;
  top: 24px;
  left: 11px;
}
.honeycomb-cell.image-position04::after {
  background-image: url("../assets/5G.png");
  top: 23px;
}
.honeycomb-cell.image-position05::after {
  background-image: url("../assets/AI.png");
  top: 26px;
  left: 16px;
}
.honeycomb-cell.image-position06::after {
  background-image: url("../assets/MR.png");
  width: 70px;
  top: 25px;
  left: 16px;
}
.honeycomb-cell.image-position07::after {
  background-image: url("../assets/digitalSynergy.png");
  width: 63px;
  top: 28px;
}
.honeycomb-cell.image-position08::after {
  background-image: url("../assets/VR.png");
  top: 26px;
  left: 16px;
}
.honeycomb-cell.image-position09::after {
  background-image: url("../assets/Web.png");
  top: 27px;
  left: 15px;
  width: 70px;
}
.honeycomb-cell.image-position10::after {
  background-image: url("../assets/AR.png");
  top: 27px;
  left: 11px;
}
.honeycomb-cell.image-position11::after {
  background-image: url("../assets/NFT.png");
  top: 27px;
}
.honeycomb-cell.image-position12::after {
  background-image: url("../assets/App.png");
  top: 27px;
  width: 63px;
}
.honeycomb-cell.image-position13::after {
  background-image: url("../assets/Movie.png");
  top: 26px;
  left: 16px;
}
.honeycomb:nth-child(1) {
  padding-right: 100px;
}
.honeycomb:nth-child(2) {
  padding-top: 100px;
  padding-right: 100px;
}
.honeycomb:nth-child(3) {
  padding-top: 200px;
  padding-right: 100px;
}
.honeycomb:nth-child(4) {
  padding-top: 300px;
  padding-right: 100px;
}
.honeycomb:nth-child(5) {
  padding-top: 400px;
  padding-right: 100px;
}
@media (max-width: 1758px) {
  .cover-area {
    width: 41.66666667%;
  }
  .cover-box {
    width: 58.33333333%;
  }
}

@media (max-width: 1505px) {
  .cover-area {
    width: 33.33333333%;
  }
  .cover-box {
    width: 66.66666667%;
  }
}

@media (max-width: 1317px) {
  .row {
    flex-direction: column-reverse;
  }
  .cover-area {
    width: 100%;
  }
  .cover-box {
    margin-right: auto;
    margin-top: 45px;
    width: 80.666667%;
  }
  @media (max-width: 1000px) {
    .image-icon {
      right: 20px;
    }
    .honeycomb:nth-child(1) {
      padding-right: 20px;
    }
    .honeycomb:nth-child(2) {
      padding-right: 20px;
    }
    .honeycomb:nth-child(3) {
      padding-right: 20px;
    }
    .honeycomb:nth-child(4) {
      padding-right: 20px;
    }
    .honeycomb:nth-child(5) {
      padding-right: 20px;
    }
  }
  @media (max-width: 900px) {
    .honeycomb {
      min-width: 300px;
    }

    .image-area {
      min-height: calc(600px / 2);
      margin: auto;
      width: 50%;
    }
    .honeycomb-cell::before,
    .honeycomb-cell-image {
      width: calc(100px / 2.5);
      height: calc(110px / 2.5);
    }
    .honeycomb-cell::after {
      width: calc(65px / 2.5);
      height: calc(74px / 2.5);
      background-size: cover;
    }
    .honeycomb li {
      min-width: calc(100px / 2.5);
      min-height: calc(100px / 2.5);
    }
    .honeycomb:nth-child(1) {
      padding-right: 0px;
    }
    .honeycomb:nth-child(2) {
      padding-top: calc(100px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(3) {
      padding-top: calc(200px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(4) {
      padding-top: calc(300px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(5) {
      padding-top: calc(400px / 2.5);
      padding-right: 0px;
    }
    .image-icon {
      top: 25px;
      right: 0px;
      width: calc(891px / 3.5);
    }
    .image-position01 {
      right: calc(429px / 2.5 - 24px);
      top: calc(1px / 2.5 + 40px);
    }
    .image-position02 {
      right: calc(422px / 2.5 - 24px);
      top: calc(33px / 2.5 + 40px);
    }
    .image-position03 {
      right: calc(607px / 2.5 - 24px);
      top: calc(-25px / 2.5 + 40px);
    }
    .image-position04 {
      right: calc(601px / 2.5 - 24px);
      top: calc(6px / 2.5 + 40px);
    }

    .image-position05 {
      right: calc(580px / 2.5 - 24px);
      top: calc(-20px / 2.5 + 40px);
    }
    .image-position06 {
      right: calc(572px / 2.5 - 24px);
      top: calc(11px / 2.5 + 40px);
    }
    .image-position07 {
      right: calc(360px / 2.5 - 24px);
      top: calc(-45px / 2.5 + 40px);
    }
    .image-position08 {
      right: calc(351px / 2.5 - 24px);
      top: calc(-14px / 2.5 + 40px);
    }
    .image-position09 {
      right: calc(344px / 2.5 - 24px);
      top: calc(17px / 2.5 + 40px);
    }
    .image-position10 {
      right: calc(336px / 2.5 - 24px);
      top: calc(50px / 2.5 + 40px);
    }
    .image-position11 {
      right: calc(327px / 2.5 - 24px);
      top: calc(83px / 2.5 + 40px);
    }

    .image-position12 {
      right: calc(524px / 2.5 - 24px);
      top: calc(-9px / 2.5 + 40px);
    }
    .image-position13 {
      right: calc(517px / 2.5 - 24px);
      top: calc(23px / 2.5 + 40px);
    }
    .honeycomb-cell.image-position01::after {
      top: calc(29px / 2.5 + 4px);
      left: calc(18px / 2.5);
      background-size: 25px;
    }

    .honeycomb-cell.image-position02::after {
      top: calc(27px / 2.5 + 5px);
      left: calc(11px / 2.5 + 0px);
      background-size: 21px;
    }
    .honeycomb-cell.image-position03::after {
      width: calc(71px / 2.5);
      height: calc(82px / 2.5);
      top: calc(24px / 2.5 + 4px);
      left: calc(11px / 2.5);
      background-size: 25px;
    }
    .honeycomb-cell.image-position04::after {
      top: calc(23px / 2.5 + 5px);
      left: 3px;
      background-size: 19px;
    }
    .honeycomb-cell.image-position05::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 25px;
    }
    .honeycomb-cell.image-position06::after {
      width: calc(70px / 2.5);
      top: calc(25px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 27px;
    }
    .honeycomb-cell.image-position07::after {
      width: calc(63px / 2.5);
      top: calc(28px / 2.5 + 5px);
      left: 3px;
      background-size: 16px;
    }
    .honeycomb-cell.image-position08::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5 + 1px);
      background-size: 25px;
    }
    .honeycomb-cell.image-position09::after {
      top: calc(27px / 2.5 + 5px);
      left: calc(15px / 2.5);
      width: calc(70px / 2.5);
      background-size: 27px;
    }
    .honeycomb-cell.image-position10::after {
      top: calc(27px / 2.5 + 4px);
      left: calc(11px / 2.5 + 1px);
      background-size: 23px;
    }
    .honeycomb-cell.image-position11::after {
      top: calc(27px / 2.5 + 5px);
      left: 4px;
      background-size: 20px;
    }
    .honeycomb-cell.image-position12::after {
      top: calc(27px / 2.5 + 5px);
      width: calc(63px / 2.5);
      left: 3px;
      background-size: 16px;
    }
    .honeycomb-cell.image-position13::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 25px;
    }
  }

  @media (max-width: 800px) {
    .honeycomb {
      min-width: 0px;
    }
    .image-area {
      min-height: calc(600px / 2);
      width: 85%;
      margin: auto;
      position: relative;
      justify-content: center;
      display: flex;
    }
    .honeycomb-cell::before,
    .honeycomb-cell-image {
      width: calc(100px / 2.5);
      height: calc(110px / 2.5);
    }
    .honeycomb-cell::after {
      width: calc(65px / 2.5);
      height: calc(74px / 2.5);
      background-size: cover;
    }
    .honeycomb li {
      min-width: calc(100px / 2.5);
      min-height: calc(100px / 2.5);
    }
    .honeycomb:nth-child(1) {
      padding-right: 0px;
    }
    .honeycomb:nth-child(2) {
      padding-top: calc(100px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(3) {
      padding-top: calc(200px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(4) {
      padding-top: calc(300px / 2.5);
      padding-right: 0px;
    }
    .honeycomb:nth-child(5) {
      padding-top: calc(400px / 2.5);
      padding-right: 0px;
    }
    .image-icon {
      top: 25px;
      right: 0px;
      width: calc(891px / 3.5);
    }
    .image-position01 {
      right: calc(429px / 2.5 + 16px);
      top: calc(1px / 2.5 + 40px);
    }
    .image-position02 {
      right: calc(422px / 2.5 + 16px);
      top: calc(33px / 2.5 + 40px);
    }
    .image-position03 {
      right: calc(607px / 2.5 + 16px);
      top: calc(-25px / 2.5 + 40px);
    }
    .image-position04 {
      right: calc(601px / 2.5 + 16px);
      top: calc(6px / 2.5 + 40px);
    }

    .image-position05 {
      right: calc(580px / 2.5 + 16px);
      top: calc(-20px / 2.5 + 40px);
    }
    .image-position06 {
      right: calc(572px / 2.5 + 16px);
      top: calc(11px / 2.5 + 40px);
    }
    .image-position07 {
      right: calc(360px / 2.5 + 16px);
      top: calc(-45px / 2.5 + 40px);
    }
    .image-position08 {
      right: calc(351px / 2.5 + 16px);
      top: calc(-14px / 2.5 + 40px);
    }
    .image-position09 {
      right: calc(344px / 2.5 + 16px);
      top: calc(17px / 2.5 + 40px);
    }
    .image-position10 {
      right: calc(336px / 2.5 + 16px);
      top: calc(50px / 2.5 + 40px);
    }
    .image-position11 {
      right: calc(327px / 2.5 + 16px);
      top: calc(83px / 2.5 + 40px);
    }

    .image-position12 {
      right: calc(524px / 2.5 + 16px);
      top: calc(-9px / 2.5 + 40px);
    }
    .image-position13 {
      right: calc(517px / 2.5 + 16px);
      top: calc(23px / 2.5 + 40px);
    }
    .honeycomb-cell.image-position01::after {
      top: calc(29px / 2.5 + 4px);
      left: calc(18px / 2.5);
      background-size: 25px;
    }

    .honeycomb-cell.image-position02::after {
      top: calc(27px / 2.5 + 5px);
      left: calc(11px / 2.5 + 0px);
      background-size: 21px;
    }
    .honeycomb-cell.image-position03::after {
      width: calc(71px / 2.5);
      height: calc(82px / 2.5);
      top: calc(24px / 2.5 + 4px);
      left: calc(11px / 2.5);
      background-size: 25px;
    }
    .honeycomb-cell.image-position04::after {
      top: calc(23px / 2.5 + 5px);
      left: 3px;
      background-size: 19px;
    }
    .honeycomb-cell.image-position05::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 25px;
    }
    .honeycomb-cell.image-position06::after {
      width: calc(70px / 2.5);
      top: calc(25px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 27px;
    }
    .honeycomb-cell.image-position07::after {
      width: calc(63px / 2.5);
      top: calc(28px / 2.5 + 5px);
      left: 3px;
      background-size: 16px;
    }
    .honeycomb-cell.image-position08::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5 + 1px);
      background-size: 25px;
    }
    .honeycomb-cell.image-position09::after {
      top: calc(27px / 2.5 + 5px);
      left: calc(15px / 2.5);
      width: calc(70px / 2.5);
      background-size: 27px;
    }
    .honeycomb-cell.image-position10::after {
      top: calc(27px / 2.5 + 4px);
      left: calc(11px / 2.5 + 1px);
      background-size: 23px;
    }
    .honeycomb-cell.image-position11::after {
      top: calc(27px / 2.5 + 5px);
      left: 4px;
      background-size: 20px;
    }
    .honeycomb-cell.image-position12::after {
      top: calc(27px / 2.5 + 5px);
      width: calc(63px / 2.5);
      left: 3px;
      background-size: 16px;
    }
    .honeycomb-cell.image-position13::after {
      top: calc(26px / 2.5 + 5px);
      left: calc(16px / 2.5);
      background-size: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    .cover-description,
    .cover-text {
      font-size: 16px;
    }

    .content-title h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
    }
    .container {
      padding: 0 20px;
    }
    .content-descliption h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    .image {
      margin-bottom: 50px;
    }
  }
}
</style>
