<template>
  <div id="list-example" class="list-group position-fixed text-end end-0 d-md-block d-none d-sm-none">
    <div class="list-menu float-end d-md-block">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        <a class="" href="#scrollspyHeading7"><img src="../assets/lih.icon.png" alt="lihIcon" class="end-1 image-pc" /></a>
      </button>
      <div class="position-relative">
        <nav id="navbar-example2" class="navbar navbar-light position-fixed">
          <ul class="">
            <li class="nav-item">
              <a class="nav-link float-end text-decoration-none list-item-1" href="#scrollspyHeading1">CONSEPT</a>
            </li>
            <li class="nav-item">
              <a class="nav-link float-end text-decoration-none list-item-2" href="#scrollspyHeading2">WORKS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link float-end text-decoration-none list-item-3" href="#scrollspyHeading3">IMAGE</a>
            </li>
            <li class="nav-item">
              <a class="nav-link float-end text-decoration-none list-item-4" href="#scrollspyHeading4">CUSTOMS</a>
            </li>
            <li class="nav-item">
              <a class="nav-link float-end text-decoration-none list-item-5" href="#scrollspyHeading5">PRODUCTS</a>
            </li>
            <img src="../assets/menu.png" alt="menu" class="img-pc position-absolute end-1 d-md-block" />
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="position-fixed header-sp">
    <a class="" href="#scrollspyHeading7"><img class="d-md-none d-sm-block image-sp position-absolute" src="../assets/lih.icon.png" alt="lihIcon" /></a>
    <div class="d-md-none d-sm-block openbtn2" data-bs-toggle="modal" data-bs-target="#staticBackdrop" @click="toggle()" v-bind:class="{ active: !active01 }"><span></span><span></span></div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex justify-content-between flex-column">
            <div class="p-2 text-center" data-bs-dismiss="modal"><a class="text-decoration-none justify-content-center" href="#scrollspyHeading1" @click="toggle()">CONSEPT</a></div>
            <div class="p-2 text-center" data-bs-dismiss="modal"><a class="text-decoration-none list-item-2" href="#scrollspyHeading2" @click="toggle()">WORKS</a></div>
            <div class="p-2 text-center" data-bs-dismiss="modal"><a class="text-decoration-none list-item-3" href="#scrollspyHeading3" @click="toggle()">IMAGE</a></div>
            <div class="p-2 text-center" data-bs-dismiss="modal"><a class="text-decoration-none list-item-4" href="#scrollspyHeading4" @click="toggle()">CUSTOMS</a></div>
            <div class="p-2 text-center" data-bs-dismiss="modal"><a class="text-decoration-none list-item-5" href="#scrollspyHeading5" @click="toggle()">PRODUCTS</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  name: "ScrollSpyExamples",
  data() {
    return {
      active01: true,
      myModal1: null,
    };
  },
  onMounted() {
    var myModal = new Modal(document.getElementById("staticBackdrop"), {
      keyboard: false,
    });
  },

  methods: {
    toggle() {
      this.active01 = !this.active01;
    },
  },
};
</script>
<style scoped>
body {
  position: relative;
}
button {
  margin-top: 65px;
  margin-bottom: 34px;
}
.navbar ul {
  list-style: none;
}
.list-group {
  z-index: 10;
}
.list-menu a {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.list-menu {
  width: 130px;
  margin-right: 110px;
}
a {
  margin-bottom: 55px;
}
.img-pc {
  top: 16px;
  right: -31px;
}
.image-sp {
  left: 37px;
  top: 43px;
  width: 62px;
  z-index: 9999;
}
a:hover {
  background-color: none;
}
.list-item-1:after {
  right: 25px;
}
.list-item-2:after {
  right: 15px;
}
.list-item-3:after {
  right: 15px;
}
.list-item-4:after {
  right: 25px;
}
.list-item-5:after {
  right: 35px;
}
.header-sp {
  z-index: 9999;
  width: 100vw;
}
.openbtn2 {
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
  right: 43px;
  top: 20px;
  z-index: 9999;
}
.openbtn2 span {
  display: inline-block;
  transition: all 0.4s;
  position: absolute;
  left: 13px;
  height: 2px;
  background-color: #fff;
}

.openbtn2 span:nth-of-type(1) {
  top: 22px;
  width: 72%;
}

.openbtn2 span:nth-of-type(2) {
  top: 37px;
  width: 72%;
}

.openbtn2.active span:nth-of-type(1) {
  top: 30px;
  left: 7px;
  transform: translateY(6px) rotate(-45deg);
  width: 100%;
}

.openbtn2.active span:nth-of-type(2) {
  top: 42px;
  left: 7px;
  transform: translateY(-6px) rotate(45deg);
  width: 100%;
}
.modal-dialog a {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.modal-body {
  height: 98vh;
  padding: 70% 0;
  display: flex;
  justify-content: center;
}
.modal-content {
  background: none;
}
.modal {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
}
@media (min-width: 768px) {
  a.active:after {
    content: "";
    position: absolute;
    width: 38px;
    height: 3px;
    display: flex;
    background-color: #fff615;
    margin-top: 5px;
  }
}
</style>
