<template>
  <div class="top">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <Header />
    <Consept />
    <Works />
    <Image />
    <Products />
    <Contact />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Products from "@/components/Products.vue";
import Contact from "@/components/Contact.vue";
import Consept from "@/components/Consept.vue";
import Works from "@/components/Works.vue";
import Image from "@/components/Image.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "TopPage",
  components: {
    Header,
    Works,
    Products,
    Contact,
    Consept,
    Image,
    Footer,
  },
};
</script>
<style scoped>
.top {
  background-color: black;
  font-family: "Hiragino Kaku Gothic ProN";
  color: #ffffff;
}
</style>
