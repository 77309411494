<template>
  <div class="movie position-relative">
    <!-- <img src="../assets/movie.bg.png" alt="movie" class="movie img-fluid position-relative" /> -->
    <div class="movie-box d-flex">
      <img src="../assets/largeButton.png" class="play-btn" alt="play" />
      <a class="" href="#scrollspyHeading1"><img src="../assets/smallButton.png" type="button" class="scroll-btn" alt="scroll" href="#scrollspyHeading1" /> </a>
      <div class="position-relative">
        <div class="position-absolute plus-top d-md-block d-none d-sm-none"></div>
        <div class="position-absolute plus-bottom d-md-block d-none d-sm-none"></div>
        <p class="top-title">
          LOCAL<br />
          INNOVATION<br />
          HUB
        </p>
        <p class="top-description">トンガルマンLIHは、DX、テクノロジーを<br />創造し地域活性のブリッジ役になる。</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.plus-top {
  width: 10px;
  height: 10px;
  border-right: solid 2px #fff615;
  border-bottom: solid 2px #fff615;
  top: -12px;
  left: -32px;
}
.plus-bottom {
  width: 10px;
  height: 10px;
  border-left: solid 2px #fff615;
  border-top: solid 2px #fff615;
  top: -4px;
  left: -24px;
}
.movie {
  z-index: 9;
}
.play-btn {
  display: block;
  width: 36%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.scroll-btn {
  display: block;
  position: absolute;
  top: 45%;
  width: 7.6%;
  left: 50%;
  transform: translateY(220%) translateX(-50%);
}
.top-title {
  z-index: 2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 0.2em;
  color: #fff615;
}
.top-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: calc(3vw + 40px);
}

.movie-box {
  padding: 0 16vw;
  z-index: 1;
  background-image: url("../assets/movie.bg.png");
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 1020px) {
  .top-title {
    font-size: 40px;
  }
  .movie-box {
    padding: 0 13vw;
  }
}
@media screen and (max-width: 767px) {
  .top-title {
    font-size: 30px;
    line-height: 36px;
  }
  .movie-box {
    padding: 0 10vw;
  }
  .play-btn {
    width: 70%;
    top: 40%;
  }

  .scroll-btn {
    width: 27.6%;
    top: 40%;
    transform: translateY(110%) translateX(-50%);
  }
}
</style>
