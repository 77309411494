<template>
  <div class="customs text-end">
    <img src="../assets/custom.title.png" alt="customTitle" class="customs-title position-relative rounded" />
    <img src="../assets/custom.icon.png" alt="customIcon" class="icon-pc position-relative rounded img-fluid" />
    <img src="../assets/custom.icon.SP.png" alt="customIcon" class="icon-sp position-relative rounded img-fluid" />
  </div>
</template>

<style scoped>
.customs {
  z-index: 1;
}
.customs-title {
  max-width: 87%;
  height: auto;
}
.icon-pc {
  display: block;
}
.icon-sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .icon-pc {
    display: none;
  }
  .icon-sp {
    display: block;
    width: 100%;
  }
}
</style>
