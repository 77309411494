<template>
  <div class="works position-relative">
    <div class="text-end position-relative">
      <img
        src="../assets/bg2.png"
        alt="workBg"
        class="work-bg position-absolute img-fluid"
      />
      <img
        src="../assets/works.title.png"
        alt="worksTitle"
        class="works-title position-relative"
      />
    </div>
    <div
      id="carouselExampleDark"
      class="carousel carousel-dark slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box position-relative">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg1.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[0].active01 }"
                  />
                  <img
                    src="../assets/mainImg2.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[0].active02 }"
                  />
                  <img
                    src="../assets/mainImg3.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[0].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg1.png"
                      alt="img"
                      @click="thumbnail(0, 1)"
                      v-bind:class="{
                        active: activeData[0].active01,
                        inactive: !activeData[0].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg2.png"
                      alt="img"
                      @click="thumbnail(0, 2)"
                      v-bind:class="{
                        active: activeData[0].active02,
                        inactive: !activeData[0].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg3.png"
                      alt="img"
                      @click="thumbnail(0, 3)"
                      v-bind:class="{
                        active: activeData[0].active03,
                        inactive: !activeData[0].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>【関西初】<br />学校に妖怪出現!?</h1>
                  <h6>
                    子ども達に支給されたタブレットを、先生達が覚えてほしい場所にかざすことでAR妖怪が出現する体験型コンテンツを枚方市の学校で実施しました。
                    子どもたちの笑顔とともに、この取り組みは
                    メディアでも取り上げていただきました。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.city.hirakata.osaka.jp/0000034415.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city1.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg4.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[1].active01 }"
                  />
                  <img
                    src="../assets/mainImg5.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[1].active02 }"
                  />
                  <img
                    src="../assets/mainImg6.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[1].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg4.png"
                      alt="img"
                      @click="thumbnail(1, 1)"
                      v-bind:class="{
                        active: activeData[1].active01,
                        inactive: !activeData[1].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg5.png"
                      alt="img"
                      @click="thumbnail(1, 2)"
                      v-bind:class="{
                        active: activeData[1].active02,
                        inactive: !activeData[1].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg6.png"
                      alt="img"
                      @click="thumbnail(1, 3)"
                      v-bind:class="{
                        active: activeData[1].active03,
                        inactive: !activeData[1].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>音と光のプラネタリウム</h1>
                  <h6>
                    地域資源である「七夕」を題材にした音と光の
                    プラネタリウムをイベントを実施。<br />
                    普段、肉眼で見れる星空の数は8600個と
                    いわれている中、特殊な機材を活用して100万個の
                    星空と立体音響で笑顔に繋がるコンテンツを演出。また天文学に精通された星空案内人のアテンドでは歓声があがりました。（360°星空映像も制作）
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2 d-grid">
                      <!-- <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.city.hirakata.osaka.jp/0000034493.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button> -->
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.city.hirakata.osaka.jp/0000037114.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city1.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg7.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[2].active01 }"
                  />
                  <img
                    src="../assets/mainImg8.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[2].active02 }"
                  />
                  <img
                    src="../assets/mainImg9.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[2].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg7.png"
                      alt="img"
                      @click="thumbnail(2, 1)"
                      v-bind:class="{
                        active: activeData[2].active01,
                        inactive: !activeData[2].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg8.png"
                      alt="img"
                      @click="thumbnail(2, 2)"
                      v-bind:class="{
                        active: activeData[2].active02,
                        inactive: !activeData[2].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg9.png"
                      alt="img"
                      @click="thumbnail(2, 3)"
                      v-bind:class="{
                        active: activeData[2].active03,
                        inactive: !activeData[2].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>天の川デジタル<br />フェスト ライトアップ</h1>
                  <h6>
                    シビックプライドの醸成を目的に、フルカラースポットライトとレーザーマッピングによる演出で
                    枚方市の川に天の川を演出。体験者がボタンを押すとライトアップの色が変わるインタラクティブ要素もふまえたコンテンツです。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.city.hirakata.osaka.jp/0000037114.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city1.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg10.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[3].active01 }"
                  />
                  <img
                    src="../assets/mainImg11.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[3].active02 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg10.png"
                      alt="img"
                      @click="thumbnail(3, 1)"
                      v-bind:class="{
                        active: activeData[3].active01,
                        inactive: !activeData[3].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg11.png"
                      alt="img"
                      @click="thumbnail(3, 2)"
                      v-bind:class="{
                        active: activeData[3].active02,
                        inactive: !activeData[3].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>観光PRゲームアプリ</h1>
                  <h6>
                    京都宇治市内の観光地がステージとなっており、観光名物や名産品が登場するゲームアプリです。各ステージでは最後にボスとのバトルが待ち受けており、市長や歴史上の登場人物とバトルを繰り広げます。またGPSと連携したスタンプラリーなど、宇治市内に実際にいなければクリアする事はできません。中身はクソゲーですが絶妙なゲームバランスとなっています。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://youtu.be/J09zchCsFcM"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city2.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg12.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[4].active01 }"
                  />
                  <img
                    src="../assets/mainImg13.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[4].active02 }"
                  />
                  <img
                    src="../assets/mainImg14.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[4].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg12.png"
                      alt="img"
                      @click="thumbnail(4, 1)"
                      v-bind:class="{
                        active: activeData[4].active01,
                        inactive: !activeData[4].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg13.png"
                      alt="img"
                      @click="thumbnail(4, 2)"
                      v-bind:class="{
                        active: activeData[4].active02,
                        inactive: !activeData[4].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg14.png"
                      alt="img"
                      @click="thumbnail(4, 3)"
                      v-bind:class="{
                        active: activeData[4].active03,
                        inactive: !activeData[4].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>観光デジタルサイネージ<br />「ミ・カ・タガイド」</h1>
                  <h6>
                    大阪泉南市の観光誘客の核事業として、<br />観光スポット4箇所に55incのデジタルサイネージを設置。観光スポットを推奨する「おみくじ」機能や特設LP
                    （SENNAN NATURE
                    SCHOOL）も制作と連携したイベント予約機能など、今現在も拡張し続けている観光コンテンツです。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2 d-grid">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://sennan-nature-school.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                      <!-- <button class="btn more" type="button">
                        <span class="text-nowrap"><a class="text-decoration-none" href="https://www.city.sennan.lg.jp/" target="_blank" rel="noopener noreferrer">もっと見る</a></span>
                      </button> -->
                    </div>
                    <img src="../assets/city3.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg15.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[5].active01 }"
                  />
                  <img
                    src="../assets/mainImg16.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[5].active02 }"
                  />
                  <img
                    src="../assets/mainImg17.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[5].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg15.png"
                      alt="img"
                      @click="thumbnail(5, 1)"
                      v-bind:class="{
                        active: activeData[5].active01,
                        inactive: !activeData[5].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg16.png"
                      alt="img"
                      @click="thumbnail(5, 2)"
                      v-bind:class="{
                        active: activeData[5].active02,
                        inactive: !activeData[5].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg17.png"
                      alt="img"
                      @click="thumbnail(5, 3)"
                      v-bind:class="{
                        active: activeData[5].active03,
                        inactive: !activeData[5].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>バーチャルマップ×<br />キャラクター商品化</h1>
                  <h6>
                    コロナ禍でも、月ヶ瀬を満喫することができる、
                    バーチャル月ヶ瀬を造成。その世界観では、
                    地元住民しかしらない情報が多数掲載しておりGPS連携していることから、バーチャルでもリアルマップとしても体験することができます。また、伝記にちなんだキャラクターは白雪ふきん様と連携し、
                    キッチンフキンとして商品化しました。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.iga-younet.co.jp/2022/02/13/52171/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city4.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg18.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[6].active01 }"
                  />
                  <img
                    src="../assets/mainImg19.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[6].active02 }"
                  />
                  <img
                    src="../assets/mainImg20.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[6].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg18.png"
                      alt="img"
                      @click="thumbnail(6, 1)"
                      v-bind:class="{
                        active: activeData[6].active01,
                        inactive: !activeData[6].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg19.png"
                      alt="img"
                      @click="thumbnail(6, 2)"
                      v-bind:class="{
                        active: activeData[6].active02,
                        inactive: !activeData[6].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg20.png"
                      alt="img"
                      @click="thumbnail(6, 3)"
                      v-bind:class="{
                        active: activeData[6].active03,
                        inactive: !activeData[6].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>高校生向けの<br />デジタル体験イベント</h1>
                  <h6>
                    高校卒業を機に町から巣立っていく高校生に向け、
                    テクノロジーを軸にしたVR、Leap motion
                    （リープモーション）体験イベントを開催。Uターンを目的をしたシビックプライドの醸成に繋げることができました。また参加者の事前映像を持参して
                    もらい一つの映像に生成するみんなで創るショートムービーも実施。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://www.city.ojiya.niigata.jp/soshiki/kikakuseisaku/terewa-kuvr202211.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city5.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg21.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[7].active01 }"
                  />
                  <img
                    src="../assets/mainImg22.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[7].active02 }"
                  />
                  <img
                    src="../assets/mainImg23.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[7].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg21.png"
                      alt="img"
                      @click="thumbnail(7, 1)"
                      v-bind:class="{
                        active: activeData[7].active01,
                        inactive: !activeData[7].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg22.png"
                      alt="img"
                      @click="thumbnail(7, 2)"
                      v-bind:class="{
                        active: activeData[7].active02,
                        inactive: !activeData[7].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg23.png"
                      alt="img"
                      @click="thumbnail(7, 3)"
                      v-bind:class="{
                        active: activeData[7].active03,
                        inactive: !activeData[7].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>京都橘大学MR<br />オープンキャンパス</h1>
                  <h6>
                    MicrosoftのMRデバイス、
                    HoloLens2を採用して京都橘大学と連携したオープンキャンパを実施。<br /><br />
                    自分の指で画像の拡大/縮小、3Dホログラムの操作、リアル翻訳など未来の授業スタイルを体験して
                    いただきました。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://youtu.be/_LkmDVEOvBs"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city6.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg24.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[8].active01 }"
                  />
                  <img
                    src="../assets/mainImg25.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[8].active02 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg24.png"
                      alt="img"
                      @click="thumbnail(8, 1)"
                      v-bind:class="{
                        active: activeData[8].active01,
                        inactive: !activeData[8].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg25.png"
                      alt="img"
                      @click="thumbnail(8, 2)"
                      v-bind:class="{
                        active: activeData[8].active02,
                        inactive: !activeData[8].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>
                    「桃太郎電鉄」<br />リアル周遊ゲームオープンキャンパス
                  </h1>
                  <h6>
                    岡山市×「桃太郎電鉄」のコラボ企画による
                    Webアプリケーションコンテンツ。<br /><br />各スポットにあるQRを読取りクイズに答えることでゴールを目指す周遊ゲームを実施。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2 d-grid">
                      <!-- <div class="blank"></div>
                      <button class="btn more" type="button">
                        <span class="text-nowrap"><a class="text-decoration-none" href="https://youtu.be/_LkmDVEOvBs" target="_blank" rel="noopener noreferrer">もっと見る</a></span>
                      </button> -->
                    </div>
                    <img src="../assets/city7.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="carousel-item">
          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="image-box">
                <div class="position-relative">
                  <img
                    src="../assets/topLine.png"
                    alt="topLine"
                    class="top-line position-absolute"
                  />
                  <img
                    src="../assets/bottomLine.png"
                    alt="bottomLine"
                    class="bottom-line position-absolute"
                  />
                  <img
                    src="../assets/mainImg26.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[9].active01 }"
                  />
                  <img
                    src="../assets/mainImg27.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[9].active02 }"
                  />
                  <img
                    src="../assets/mainImg28.png"
                    class="image"
                    alt="img"
                    v-bind:class="{ 'd-none': !activeData[9].active03 }"
                  />
                </div>
              </div>
              <div class="carousel-indicator justify-content-center">
                <div class="justify-content-between thumbnail-box d-flex">
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 1"
                      src="../assets/mainImg26.png"
                      alt="img"
                      @click="thumbnail(9, 1)"
                      v-bind:class="{
                        active: activeData[9].active01,
                        inactive: !activeData[9].active01,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 2"
                      src="../assets/mainImg27.png"
                      alt="img"
                      @click="thumbnail(9, 2)"
                      v-bind:class="{
                        active: activeData[9].active02,
                        inactive: !activeData[9].active02,
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <img
                      type="button"
                      aria-label="Slide 3"
                      src="../assets/mainImg28.png"
                      alt="img"
                      @click="thumbnail(9, 3)"
                      v-bind:class="{
                        active: activeData[9].active03,
                        inactive: !activeData[9].active03,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="works-box d-flex justify-content-center flex-column col-md-4"
            >
              <div class="">
                <div class="text-start">
                  <h1>尼崎城デジタル<br />コンテンツ</h1>
                  <h6>
                    兵庫県尼崎市の尼崎城でHTC VIVEトラッカーを
                    採用したデジタルコンテンツを実施。
                    模造刀にVIVEトラッカーを装着しモニターから表示される対象物を斬ることができます。大人から、
                    子ども、外国人まで楽しむことができる体験型 コンテンツです。
                  </h6>
                  <div class="d-flex justify-content-between detail">
                    <div class="me-md-2">
                      <button class="btn more" type="button">
                        <span class="text-nowrap"
                          ><a
                            class="text-decoration-none"
                            href="https://amagasaki-castle.jp/guide/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >もっと見る</a
                          ></span
                        >
                      </button>
                    </div>
                    <img src="../assets/city8.png" class="city" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
          src="../assets/prev.button.png"
          alt="img"
        />
        <img
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
          src="../assets/next.button.png"
          alt="img"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
import Carousel from "bootstrap/js/dist/carousel";
onMounted(() => {
  const carousel = new Carousel("#myCarousel", {
    keyboard: false,
    pause: true,
    slide: false,
  });
});

export default {
  created() {},
  data() {
    return {
      activeData: [
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
        {
          active01: true,
          active02: false,
          active03: false,
        },
      ],
    };
  },
  methods: {
    thumbnail(slide, target) {
      this.activeData[slide].active01 = target == 1 ? true : false;
      this.activeData[slide].active02 = target == 2 ? true : false;
      this.activeData[slide].active03 = target == 3 ? true : false;
    },
  },
};
</script>
<style scoped>
.blank {
  height: 8px;
}
.top-line {
  top: -50%;
  right: -50%;
  width: 100%;
  height: auto;
}
.bottom-line {
  bottom: -50%;
  left: -50%;
  width: 100%;
  height: auto;
}
.inactive {
  opacity: 0.5;
}

.thumbnail-box {
  margin-top: 34px;
}
.thumbnail-box .active {
  transition: all 1s;
  opacity: 1;
}
.col-md-3 {
  width: 30%;
}
a {
  color: #fde71c;
}
a:hover {
  color: #fde71c;
}
.works {
  z-index: 7;
  min-height: 1000px;
  margin-bottom: 160px;
}
.works-box {
  padding: 0 48px 0 3%;
}
.row {
  margin: 0;
}
.works-title {
  max-width: 70%;
  height: auto;
}
.image {
  width: 100%;
  /* max-width: 666px; */
  max-height: 573px;
  outline: 1px solid rgb(255, 255, 255);
  outline-offset: -7px;
}
.image-box {
  justify-content: right;
}
.carousel-inner {
  padding-top: 170px;
}
.carousel-indicator img {
  width: 100%;
  max-height: 117px;
  object-fit: cover;
}
.carousel-indicator {
  position: inherit;
  margin-right: 0;
  margin-left: 0;
}
.carousel-control-prev {
  width: 100px;
  top: calc(10vw + 170px);
  transform: translateX(70%);
}
.carousel-control-next {
  width: 100px;
  top: calc(10vw + 170px);
  transform: translateX(-70%);
}
.carousel-item {
  width: 100%;
}
.text-start {
  color: #ffffff;
}
.text-start h1 {
  font-style: normal;
  font-weight: 700;
  line-height: 61px;
}
.detail {
  margin-top: 10vh;
}
.more {
  border: 1px solid #fff615;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  height: 46px;
  width: 128px;
  color: #fde71c;
  padding: 12px 21px;
  margin-top: 10px;
}

.more:hover {
  color: #fde71c;
  border: 1px solid #fff615;
  opacity: 0.75;
}
.work-bg {
  right: 40%;
  top: -300%;
}
.city {
  width: 200px;
  height: auto;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 61px;
}

@media screen and (max-width: 1024px) {
  .works-box {
    width: 41.66666667%;
  }
  .works {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
  }
  .carousel-inner {
    padding-top: 30px;
  }
  .city {
    width: 162px;
  }

  .works-box {
    margin-top: 28px;
    margin-left: 48px;
    width: 100%;
  }
  .image {
    max-width: 298px;
    max-height: 256px;
  }
  .image-box {
    display: flex;
    justify-content: center;
  }
  .carousel-indicator {
    display: flex;
  }
  .thumbnail-box {
    width: 298px;
  }
  .carousel-indicator img {
    width: 100%;
    max-width: 89px;
    max-height: 51px;
    object-fit: cover;
  }

  .carousel-control-prev {
    width: 100px;
    transform: translate(10%, 0%);
  }
  .carousel-control-next {
    width: 100px;
    transform: translate(-10%, 0%);
  }
  .carousel-control-prev {
    top: 128px;
  }
  .carousel-control-next {
    top: 128px;
  }
  .top-line {
    top: -35%;
    right: -50%;
  }
  .bottom-line {
    bottom: -35%;
    left: -50%;
  }
}
</style>
