<template>
  <div class="top">
    <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example" tabindex="0">
      <div id="scrollspyHeading7">
        <Header />
        <Movie />
        <Consept id="scrollspyHeading1" />
        <Works id="scrollspyHeading2" />
        <Image id="scrollspyHeading3" />
        <Customs id="scrollspyHeading4" />
        <Products id="scrollspyHeading5" />
        <Contact id="scrollspyHeading6" />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Movie from "@/components/Movie.vue";
import Products from "@/components/Products.vue";
import Contact from "@/components/Contact.vue";
import Consept from "@/components/Consept.vue";
import Works from "@/components/Works.vue";
import Image from "@/components/Image.vue";
import Customs from "@/components/Customs.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "TopPage",
  components: {
    Header,
    Movie,
    Works,
    Products,
    Contact,
    Consept,
    Image,
    Customs,
    Footer,
  },
};
</script>
<style scoped>
body {
  font-family: "Hiragino Kaku Gothic ProN";
  background-color: yellow;
}
.top {
  background-color: black;

  color: #ffffff;
  overflow: hidden;
}
</style>
