<template>
  <div class="contact pb-lg-5 mb-5 position-relative">
    <div class="text-end">
      <img src="../assets/bg4.png" alt="productBg" class="product-bg position-absolute img-fluid d-md-none d-sm-block" />
      <img src="../assets/contact.title.png" alt="test" class="contact-title position-relative" />
    </div>
    <div class="container justify-content-center position-relative">
      <div class="row justify-content-center">
        <div class="contact-box tel col-4">
          <div class="">
            <p class="h4">電話番号</p>
            <p class="h6">
              【大阪オフィス】06-6131-7266<br />
              【東京オフィス】03-6416-9622
            </p>
          </div>
        </div>
        <a class="contact-box form col-4 text-decoration-none" href="https://tongullman.co.jp/#section-contact" target="_blank" rel="noopener noreferrer">
          <div class="">
            <div class="text-center"><p class="h4">お問い合わせ</p></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  position: relative;
  overflow: hidden;
}
.product-bg {
  right: -25%;
  top: -37%;
}
.container {
  margin-top: 9%;
}
.contact-title {
  max-width: 70%;
  height: auto;
}
.contact-box {
  min-width: 301px;
  min-height: 91px;
  background: transparent;
  background-color: #ffffff;
  color: black;
  border-left: solid 13px #fff615;
  margin: 0 24px 19.2px;
  letter-spacing: 0.2em;
  color: #1c1c1c;
}
.contact-box a {
  color: #1c1c1c;
}
.tel .h4 {
  padding: 5% 0 3% 5%;
  font-weight: bold;
}
.form {
  display: flex;
  justify-content: center;
  padding: 4% 0;
  align-items: center;
}
.form .h4 {
  margin-bottom: 0;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .tel .h6 {
    font-size: 13px;
  }
}
</style>
