<template>
  <div class="products position-relative">
    <div class="text-start">
      <img src="../assets/bg4.png" alt="productBg" class="product-bg position-absolute img-fluid d-md-block d-none d-sm-none" />
      <img src="../assets/product.title.png" alt="test" class="product-title rounded position-relative" />
    </div>
    <div class="row">
      <div class="col-md-7 position-relative">
        <img src="../assets/products.icon.png" alt="test" class="products-icon img-fluid" />
      </div>
      <div class="description col-md-4 position-relative d-flex justify-content-between flex-column">
        <div class="products-text position-relative">
          <h4>メタバース</h4>
          <h6>
            メタバースは仮想空間で自身のアバターを生成して世界の人々をコミュニケーションを図ることができるサービス です。<br />イベントや新しい形の教育など、地域のニーズに 沿った世界を構築します。
          </h6>
        </div>
        <div class="products-text position-relative">
          <h4>DAO</h4>
          <h6>DAOは分散型自立組織を指し、ブロックチェーン上に成立する管理者のいない新しい形の組織形態です。例えば、地域資源のコミュニティを形成したり、その地域のコアファンを作ることができます。</h6>
        </div>
        <div class="products-text position-relative">
          <h4>NFT</h4>
          <h6>
            NFT＝「唯一無二のデジタルデータ」<br />
            ふるさと納税の返礼品や、地域資源のNFT化など様々な形で地域の新しい収益源になります。オウンドメディアのような一過性ではない価値を創造します。
          </h6>
        </div>
        <div class="products-text position-relative">
          <h4>AR・VR・MR</h4>
          <h6>
            AR・VR・MR（複合現実）による<br />
            バーチャルの世界と地域の現実を<br />
            融合させこれまでにはない魅力的な地域演出をいたします。またICT教育の加速化にも貢献することができます。
          </h6>
        </div>
        <div class="products-text position-relative">
          <h4>データ連携等システム開発</h4>
          <h6>データ連携システムは地域のこれまでの歴史（蓄積されたデータ）をより有効に活用することができます。これにより スマートシティ化や都市OSの加速化に貢献することができます。</h6>
        </div>
        <div class="products-text position-relative">
          <h4>インバウンドPR</h4>
          <h6>
            グループ会社との連携により主に中国などアジア中心<br />
            とした、効果的なインバウンドPRを行うことができます。<br />
            海外インフルエンサーの起用やその国がもつSNSを<br />
            最大限活用したプロモーションをプロデュースします。
          </h6>
        </div>
        <div class="products-text position-relative">
          <h4>Webサイト・アプリ</h4>
          <h6>
            テクノロジーを活用したWebサイトやデータ連携した<br />
            アプリなど、体験者目線のUXやUI設計にて目を引くだけ<br />
            ではない流入経路を構築します。また地域資源を活用したゲームアプリなども当社の得意領域です。
          </h6>
        </div>
        <div class="products-text position-relative">
          <h4>リアルイベント</h4>
          <h6>
            当社が培ったテクノロジーをベースにICTイベントや地域のプロモーションベントをプロデュースします。大人だけ<br />
            でなく子ども達の未来に繋ぐ刺激的な体験を演出します。
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.products {
  margin-top: 9%;
  overflow: hidden;
  position: relative;
  background-color: black;
}
.product-title {
  max-width: 95%;
  height: auto;
}
.product-bg {
  right: -25%;
  top: -15vw;
}

.description {
  padding-top: 15vw;
  padding-bottom: 6vw;
  transform: translate(-3vw);
  z-index: 1;
}
.products-text {
  text-align: left;
  color: aliceblue;
}
.products-Title {
  font-size: 20px;
}
.products-text h4 {
  font-weight: 700;
  line-height: 35px;
}

@media screen and (max-width: 767px) {
  .products-text {
    font-size: 16px;
    padding: 7vw 36px;
  }
  .products-icon {
    position: absolute;
    opacity: 0.5;
    max-width: 95%;
    height: auto;
  }
}
@media screen and (max-width: 425px) {
  .products-text {
    font-size: 16px;
    padding: 20px 36px;
  }
  .description {
    transform: translate(0vw);
  }
  .products-icon {
    position: absolute;
    opacity: 0.5;
    max-width: 110%;
    height: auto;
  }
}
</style>
