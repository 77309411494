<template>
  <div class="consept position-relative">
    <div class="text-start position-relative">
      <img
        src="../assets/bg1.png"
        alt="conceptBg"
        class="concept-bg position-absolute start-50 img-fluid"
      />
      <img
        src="../assets/concept.title.png"
        alt="conceptTitle"
        class="consept-title position-relative rounded img-fluid"
      />
    </div>

    <div class="consept-box container position-relative">
      <div class="row d-flex justify-content-center py-md-5">
        <div class="col-md-7">
          <div class="consept-area pb-4 position-relative">
            <h4 class="lh-base">
              <span class="yellow">「地域文化×テクノロジー」</span>による
              <br />新しい地域創りを<span class="yellow">プロデュース</span>
            </h4>
            <img
              src="../assets/circle.png"
              alt="circle"
              class="circle position-absolute"
            />
          </div>
          <div class="consept-descliption">
            <h6>
              トンガルマン LIHは、地域資源をデジタルでさらに磨き上げ<br />新しい地域創りをプロデュースします。
            </h6>
          </div>
        </div>
        <div class="col-md-5">
          <h6 class="consept-detail">
            近年の多様な環境変化により、地域におけるDXなどテクノロジーの導入は地域を守り発展させる上でなくてはならないものとなりました。<br
              class="d-md-none d-sm-block"
            /><br
              class="d-md-none d-sm-block"
            />一方でテクノロジーの進化と合わせて導入までのプロセスは多難な一途をたどります。
            <br />
            <br
              class="d-md-none d-sm-block"
            />トンガルマンLIHは地域のカルチャーやあたたかさは守りつつ、地域のみなさまと足並みをそろえてテクノロジーの世界観を演出したいと思っております。
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.consept-title {
  padding: 25px 0 60px;
}
.circle {
  right: -16px;
  bottom: -17px;
}
.top-title {
  position: absolute;
  width: 425px;
  height: 183px;
  left: 320px;
  top: 736px;
  z-index: 2;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 0.2em;

  color: #fff615;
}
.concept-bg {
  top: -150% !important;
}
.works-title {
  max-width: 70%;
  height: auto;
}
.consept {
  z-index: 8;
  margin-bottom: 160px;
}
.consept-box {
  text-align: left;
}

.consept-icon {
  width: 50vw;
  margin-right: 30px;
}
.consept-text {
  color: aliceblue;
}

.consept-area {
  border-bottom: var(--bs-border-width) var(--bs-border-style) #585958;
  border-right: var(--bs-border-width) var(--bs-border-style) #585958;
}
.consept-area h4 {
  font-weight: bold;
  font-size: 42px;
  text-align: left;
  font-style: normal;
}
.consept-descliption h6 {
  border-right: var(--bs-border-width) var(--bs-border-style) #585958;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #ebebeb;
  padding-top: 40px;
}
.consept-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 6.5px 0px;
  color: #ebebeb;
}

.yellow {
  color: #fff615;
}

@media screen and (max-width: 1020px) {
  .consept-area h4 {
    font-size: 33px;
  }
  .consept-box {
    padding: 0 28px 0 28px;
  }
}
@media screen and (max-width: 767px) {
  .consept-title {
    padding: 25px 0;
  }
  .consept-area h4 {
    font-size: 24px;
  }
  h6 {
    font-size: 16px;
  }
  .consept-text {
    padding: 20px;
  }
}
</style>
